<template>
  <b-modal
    v-model="editAgendaAudienceModal"
    size="xl"
    no-fade ref="edit-agenda-audience"
    id="edit-agenda-audience"
    :no-close-on-backdrop="true"
    hide-footer
    hide-header
    hide-backdrop
  >
    <div class="row">
      <div class="col-md-12 stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-10">
                <h4 class="card-title mb-4">
                  <span v-if="modalTitle">{{ modalTitle }}</span>
                  <span v-else>Editar Audiencia</span>
                </h4>
              </div>
              <div class="col-2">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="reset">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
            </div>
            <form class="forms-sample">
              <div class="row">
                <div class="form-group col-lg-6">
                  <label for="date">Fecha</label>
                  <datetime v-model="payload.date" :config="dateConfig" id="date" class="form-control"></datetime>
                  <div v-if="errors.hasOwnProperty('date')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.date[0]}}</small>
                  </div>
                </div>
                <div class="form-group col-lg-6" v-if="minUI && !setClient">
                  <label for="client_id">Cliente</label>
                  <v-select
                    disabled
                    v-model="payload.client_id"
                    :options="clients_dropdown"
                    id="client_id"
                    :reduce="r => r.code"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        No se encontraron resultados para <em>{{ search }}</em>.
                      </template>
                      <em style="opacity: 0.5;" v-else>
                        Empiece a escribir para buscar.
                      </em>
                    </template>
                  </v-select>
                  <div v-if="errors.hasOwnProperty('client_id')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.client_id[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-6" v-if="minUI && !setRecord">
                  <label for="record_id">Expediente</label>
                  <v-select
                    disabled v-model="payload.record_id"
                    :options="records_dropdown"
                    id="record_id"
                    :reduce="r => r.code"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        No se encontraron resultados para <em>{{ search }}</em>.
                      </template>
                      <em style="opacity: 0.5;" v-else>
                        Empiece a escribir para buscar.
                      </em>
                    </template>
                  </v-select>
                  <div v-if="errors.hasOwnProperty('record_id')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.record_id[0]}}</small>
                  </div>
                </div>
              </div>

              <div class="row mb-3" v-if="minUI && Array.isArray(payload.audience_notes) && payload.audience_notes.length > 0">
                <div class="col-12 mb-4 mt-4 py-0">
                  <h4 class="m-0 p-0">Argumentos</h4>
                </div>

                <div
                  v-for="(row, indexP) in this._.chunk(payload.audience_notes, 3)"
                  class="col-12 audience_notes_container pt-4 pb-1 mb-2"
                  :key="indexP"
                >
                  <div v-for="(item, index) in row" class="audience_notes form-group mb-4" :key="index">
                    <label :for="'audience_notes_' + index " class="pb-1 mb-1">
                      {{ item.type | audienceType }}
                    </label>
                    <div class="input-group">
                      <textarea
                        cols="3"
                        rows="2"
                        v-model="item.details"
                        class="form-control"
                        :id="'audience_notes_' + index"
                        :name="'audience_notes_' + index"
                      ></textarea>
                      <div v-if="payload.status === 1" class="input-group-prepend">
                        <button
                          @click.prevent="removeNotes(index)"
                          class="btn btn-outline-danger"
                          type="button"
                        >
                          <i class="mdi mdi-close"></i>
                        </button>
                      </div>
                    </div>
                    <div
                      v-if="errors.hasOwnProperty('audience_notes.' + index + '.details')"
                      class="text-left font-weight-bold text-danger mb-4 mt-2"
                    >
                      <small>Éste campo es requerido.</small>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row justify-content-end mt-5" v-if="minUI && payload.status == 1">
                <div class="col-12 mb-2 col-md-auto mr-auto">
                  <div class="dropdown">
                    <button class="btn btn-outline-dark btn-md dropdown-toggle btn-block" type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Agregar Resultado
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <a class="dropdown-item" href="#" @click.prevent="addNote(1)">
                        <span><i class="mdi mdi-account-plus"></i></span> <span>Petición del Cliente</span>
                      </a>
                      <div class="dropdown-divider"></div>
                      <a class="dropdown-item" href="#" @click.prevent="addNote(2)">
                        <span><i class="mdi mdi-account-minus"></i></span> <span>Petición de la Contraparte</span>
                      </a>
                      <div class="dropdown-divider"></div>
                      <a class="dropdown-item" href="#" @click.prevent="addNote(3)">
                        <span><i class="mdi mdi-security"></i></span> <span>Desición del Tribunal</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-12 mb-2 col-md-auto" v-if="Array.isArray(payload.audience_notes) && payload.audience_notes.length">
                  <button @click.prevent="reschedule(payload)" class="btn btn-gradient-info btn-md btn-block">Re-agendar</button>
                </div>
                <div class="col-12 mb-2 col-md-auto">
                  <button @click.prevent="updateModel" class="btn btn-gradient-info btn-md btn-block">Continuar</button>
                </div>
                <div class="col-12 mb-2 col-md-auto" v-if="Array.isArray(payload.audience_notes) && payload.audience_notes.length">
                  <button @click.prevent="complete(payload)" class="btn btn-gradient-info btn-md btn-block">Completar</button>
                </div>
              </div>
              <div v-if="!minUI" class="row justify-content-end mt-5">
                <div class="col-12 mb-2 col-md-auto">
                  <button @click.prevent="updateModel" type="button" class="btn btn-gradient-info btn-md btn-block">Ok</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { Spanish } from 'flatpickr/dist/l10n/es.js'
import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate.js'

export default {
  name: 'EditAgendaAudience',
  props: {
    payload: {
      type: Object,
      required: true
    },
    setRecord: {
      type: Number,
      required: false
    },
    setClient: {
      type: Number,
      required: false
    }
  },
  data () {
    return {
      errors: {},
      selected: {},
      modalTitle: '',
      minUI: true,
      audienceTypes: [1, 2, 3],
      dateConfig: {
        altInput: true,
        enableTime: true,
        plugins: [new ConfirmDatePlugin({
          confirmIcon: '<i class="fa fa-check"></i>',
          confirmText: 'OK ',
          showAlways: false
        })],
        altFormat: 'F j, Y G:i K',
        dateFormat: 'Y-m-d H:i',
        defaultHour: 9,
        defaultMinute: 0,
        time_24hr: false,
        locale: Spanish
      }
    }
  },
  watch: {
    payload: {
      immediate: true,
      deep: true,
      handler (value) {
        const today = this.$moment()
        if (this.$moment(value.date).isBefore(today, 'd')) {
          this.errors.date = ['La fecha es inferior al día de hoy']
        } else {
          this.errors.date = ''
        }
        if (value.lawyer_id) {
          // this.getRecordsOfLawyer({
          //   lawyer_id: value.lawyer_id
          // })
        }
      }
    }
  },
  methods: {
    ...mapMutations({
      TOGGLE_EDIT_AGENDA_AUDIENCE_MODAL: 'Agenda/TOGGLE_EDIT_AGENDA_AUDIENCE_MODAL'
    }),
    ...mapActions({
      update: 'Agenda/update',
      create: 'Agenda/create',
      getClients: 'Client/getClients',
      getRecordsDropdown: 'Record/getRecordsDropdown'
    }),
    getRecordsOfLawyer (payload) {
      this.getRecordsDropdown({
        client_id: payload
      })
    },
    updateModel () {
      this.update(this.payload)
        .then(() => {
          // this.reset()
          this.$swal('Audiencia actualizada', '', 'success')
        })
        .catch((error) => {
          this.errors = error.response.data.errors
          if (!error.response.data.errors) {
            this.$swal(error.response.data.message, '', 'error')
          }
        })
    },
    createModel () {
      this.minUI = false
      this.payload.status = 1
      this.payload.audience_notes = []
      this.create(this.payload)
        .then(response => {
          this.modalTitle = 'Reagendar Audiencia'
          this.payload = response
        })
        .catch((error) => {
          self.errors = error.response.data.errors
          if (!error.response.data.errors) {
            this.$swal(error.response.data.message, '', 'error')
          }
        })
    },
    reschedule () {
      this.payload.status = 3
      this.update(this.payload)
        .then(() => {
          this.createModel()
        })
        .catch((error) => {
          this.errors = error.response.data.errors
          if (!error.response.data.errors) {
            this.$swal(error.response.data.message, '', 'error')
          }
        })
    },
    complete () {
      this.payload.status = 2
      this.updateModel()
    },
    cancel () {
      this.payload.status = 4
      this.updateModel()
    },
    reset () {
      this.errors = {}
      this.modalTitle = ''
      this.date = null
      this.audienceTypes = [1, 2, 3]
      this.minUI = true
      this.TOGGLE_EDIT_AGENDA_AUDIENCE_MODAL(false)
      this.$events.fire('refresh-table')
    },
    addNote (payload) {
      const self = this
      // if (self.payload.audience_notes.length || !this.lodash.includes(this.audienceTypes, payload)) {
      //   self.payload.audience_notes.push({
      //     type: payload,
      //     details: ''
      //   })
      //   return false
      // }
      self.payload.audience_notes.push({
        type: payload,
        details: ''
      })
      this.lodash.remove(this.audienceTypes, function (e) {
        return e === payload
      })
      this.lodash.forEach(this.audienceTypes, function (value) {
        self.payload.audience_notes.push({
          type: value,
          details: ''
        })
      })
      this.audienceTypes = [1, 2, 3]
    },
    removeNotes (index) {
      this.$delete(this.payload.audience_notes, index)
    }
  },
  computed: {
    ...mapGetters({
      editAgendaAudienceModal: 'Agenda/editAgendaAudienceModal',
      clients_dropdown: 'Client/clients_dropdown',
      records_dropdown: 'Record/records_dropdown'
    })
  },
  filters: {
    audienceType (payload) {
      let string
      switch (payload) {
        case 1:
          string = 'Petición del Cliente'
          break
        case 2:
          string = 'Petición de la Contraparte'
          break
        case 3:
          string = 'Decisión del Tribunal'
          break
      }
      return string
    }
  },
  created () {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'edit-agenda-audience') {
        if (this.setRecord) {
          this.form.record_id = this.setRecord
        } else {
          this.getRecordsDropdown()
        }
        if (this.setClient) {
          this.form.client_id = this.setClient
        } else {
          this.getClients()
        }
      }
    })
  }
}
</script>

<style>
  .audience_notes_container:nth-child(odd) {
    background-color: rgba(183, 183, 183, .2);
    border-radius: .3rem;
  }
</style>
