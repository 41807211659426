<template>
  <b-modal v-model="editAgendaAppointmentModal" size="xl" no-fade ref="edit-agenda-appointment" id="edit-agenda-appointment" :no-close-on-backdrop="true" hide-footer hide-header hide-backdrop>
    <div class="row">
      <div class="col-md-12 stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-10">
                <h4 class="card-title mb-4">
                  <span v-if="modalTitle">{{ modalTitle }}</span>
                  <span v-else>Editar Cita</span>
                </h4>
              </div>
              <div class="col-2">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="reset">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
            </div>
            <form class="forms-sample">
              <div class="row">
                <div class="form-group col-lg-6">
                  <label for="date">Fecha</label>
                  <datetime v-model="payload.date" :config="dateConfig" id="date" class="form-control"></datetime>
                  <div v-if="errors.hasOwnProperty('date')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.date[0]}}</small>
                  </div>
                </div>
                <div class="form-group col-lg-6" v-if="!setClient">
                  <label for="client_id">Solicitante</label>
                  <v-select
                    v-model="payload.client_id"
                    :options="clients_dropdown"
                    id="client_id"
                    :reduce="r => r.code"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        No se encontraron resultados para <em>{{ search }}</em>.
                      </template>
                      <em style="opacity: 0.5;" v-else>
                        Empiece a escribir para buscar.
                      </em>
                    </template>
                  </v-select>
                  <div v-if="errors.hasOwnProperty('client_id')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.client_id[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-6">
                  <label for="lawyer">Abogado</label>
                  <v-select
                    v-model="payload.lawyer_id"
                    :options="lawyers_dropdown"
                    id="lawyer"
                    :reduce="r => r.code"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        No se encontraron resultados para <em>{{ search }}</em>.
                      </template>
                      <em style="opacity: 0.5;" v-else>
                        Empiece a escribir para buscar.
                      </em>
                    </template>
                  </v-select>
                  <div v-if="errors.hasOwnProperty('lawyer_id')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.lawyer_id[0]}}</small>
                  </div>
                </div>
                <div class="form-group col-lg-6" v-if="payload.lawyer_id && records_dropdown.length && !setRecord">
                  <label for="record_id">Expediente</label>
                  <v-select
                    v-model="payload.record_id"
                    :options="records_dropdown"
                    id="record_id"
                    :reduce="r => r.code"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        No se encontraron resultados para <em>{{ search }}</em>.
                      </template>
                      <em style="opacity: 0.5;" v-else>
                        Empiece a escribir para buscar.
                      </em>
                    </template>
                  </v-select>
                  <div v-if="errors.hasOwnProperty('record_id')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.record_id[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="notes">Notas</label>
                <textarea v-model="payload.notes" name="notes" id="notes" cols="30" rows="5" class="form-control"></textarea>
                <div v-if="errors.hasOwnProperty('notes')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                  <small>{{errors.notes[0]}}</small>
                </div>
              </div>
              <div class="row justify-content-end align-items-center">
                <div v-if="payload.status == 1" class="col-12 col-lg-auto order-4 order-lg-1 mt-2">
                  <a href="#" class="btn btn-gradient-danger btn-md btn-block" @click.prevent="cancel(payload)"><i class="mdi mdi-block-helper"></i> Cancelar</a>
                </div>
                <div v-if="payload.status == 1" class="col-12 col-lg-auto order-3 order-lg-1 mt-2">
                  <a href="#" class="btn btn-gradient-warning btn-md btn-block" @click.prevent="reschedule(payload)"><i class="mdi mdi-calendar-clock"></i> Re-agendar</a>
                </div>
                <div v-if="payload.status == 1" class="col-12 col-lg-auto order-2 order-lg-1 mt-2">
                  <a href="#" class="btn btn-gradient-success btn-md btn-block" @click.prevent="complete(payload)"><i class="mdi mdi-check-circle"></i> Completar</a>
                </div>
                <div class="col-12 col-lg-auto order-1 order-lg-1 mt-2">
                  <button @click.prevent="updateModel" class="btn btn-gradient-info btn-md btn-block">Actualizar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { Spanish } from 'flatpickr/dist/l10n/es.js'
import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate.js'

export default {
  name: 'EditAgendaAppointment',
  props: {
    setRecord: {
      type: Number,
      required: false
    },
    setClient: {
      type: Number,
      required: false
    },
    payload: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      errors: {},
      selected: {},
      modalTitle: '',
      dateConfig: {
        altInput: true,
        enableTime: true,
        plugins: [new ConfirmDatePlugin({
          confirmIcon: '<i class="fa fa-check"></i>',
          confirmText: 'OK ',
          showAlways: false
        })],
        altFormat: 'F j, Y G:i K',
        dateFormat: 'Y-m-d H:i',
        time_24hr: false,
        locale: Spanish // locale for this instance only,
      }
    }
  },
  watch: {
    payload: {
      immediate: true,
      deep: true,
      handler (value) {
        const today = this.$moment()
        if (this.$moment(value.date).isBefore(today, 'd')) {
          this.errors.date = ['La fecha es inferior al día de hoy']
        } else {
          this.errors.date = ''
        }
        if (value.client_id) {
          this.getRecordsOfClient({
            client_id: value.client_id
          })
        }
      }
    }
  },
  methods: {
    ...mapMutations({
      TOGGLE_EDIT_AGENDA_APPOINTMENT_MODAL: 'Agenda/TOGGLE_EDIT_AGENDA_APPOINTMENT_MODAL'
    }),
    ...mapActions({
      update: 'Agenda/update',
      create: 'Agenda/create',
      getClients: 'Client/getClients',
      getLawyersDropdown: 'User/getAllLawyers',
      getRecordsDropdown: 'Record/getRecordsDropdown'
    }),
    getRecordsOfClient (payload) {
      this.getRecordsDropdown(payload)
    },
    updateModel () {
      this.update(this.payload)
        .then(() => {
          this.reset()
        })
        .catch((error) => {
          this.errors = error.response.data.errors
          if (!error.response.data.errors) {
            this.$swal(error.response.data.message, '', 'error')
          }
        })
    },
    createModel () {
      this.payload.status = 1
      this.create(this.payload)
        .then(response => {
          this.modalTitle = 'Reagendar cita'
          this.payload = response
        })
        .catch((error) => {
          self.errors = error.response.data.errors
          if (!error.response.data.errors) {
            this.$swal(error.response.data.message, '', 'error')
          }
        })
    },
    reschedule () {
      this.payload.status = 3
      this.update(this.payload)
        .then(() => {
          this.$swal('Operación Completada', '', 'success')
            .then(() => {
              this.createModel()
            })
        })
        .catch((error) => {
          this.errors = error.response.data.errors
          if (!error.response.data.errors) {
            this.$swal(error.response.data.message, '', 'error')
          }
        })
    },
    complete () {
      this.payload.status = 2
      this.updateModel()
    },
    cancel () {
      this.payload.status = 4
      this.updateModel()
    },
    reset () {
      this.errors = {}
      this.modalTitle = ''
      this.TOGGLE_EDIT_AGENDA_APPOINTMENT_MODAL(false)
      this.$events.fire('refresh-table')
    }
  },
  computed: {
    ...mapGetters({
      editAgendaAppointmentModal: 'Agenda/editAgendaAppointmentModal',
      clients_dropdown: 'Client/clients_dropdown',
      lawyers_dropdown: 'User/lawyers_dropdown',
      records_dropdown: 'Record/records_dropdown'
    })
  },
  created () {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'edit-agenda-appointment') {
        if (!this.setClient) {
          this.getClients()
        }
        this.getLawyersDropdown()
      }
    })
  }
}
</script>
