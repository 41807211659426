var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"create-agenda-appointment",attrs:{"no-fade":"","size":"xl","id":"create-agenda-appointment","no-close-on-backdrop":true,"hide-footer":"","hide-header":"","hide-backdrop":""},model:{value:(_vm.createAgendaAppointmentModal),callback:function ($$v) {_vm.createAgendaAppointmentModal=$$v},expression:"createAgendaAppointmentModal"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 stretch-card"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10"},[_c('h4',{staticClass:"card-title mb-4"},[_vm._v("Agregar Cita")])]),_c('div',{staticClass:"col-2"},[_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":_vm.reset}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])]),_c('form',{staticClass:"forms-sample"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-lg-6"},[_c('label',{attrs:{"for":"date"}},[_vm._v("Fecha")]),_c('datetime',{staticClass:"form-control",attrs:{"config":_vm.dateConfig,"id":"date"},model:{value:(_vm.form.date),callback:function ($$v) {_vm.$set(_vm.form, "date", $$v)},expression:"form.date"}}),(_vm.errors.hasOwnProperty('date'))?_c('div',{staticClass:"text-left font-weight-bold text-danger mt-2"},[_c('small',[_vm._v(_vm._s(_vm.errors.date[0]))])]):_vm._e()],1),(!_vm.setClient)?_c('div',{staticClass:"form-group col-lg-6"},[_c('label',{attrs:{"for":"client_id"}},[_vm._v("Solicitante")]),_c('v-select',{attrs:{"options":_vm.clients_dropdown,"id":"client_id","reduce":function (r) { return r.code; }},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
return [(searching)?[_vm._v(" No se encontraron resultados para "),_c('em',[_vm._v(_vm._s(search))]),_vm._v(". ")]:_c('em',{staticStyle:{"opacity":"0.5"}},[_vm._v(" Empiece a escribir para buscar. ")])]}}],null,false,194695259),model:{value:(_vm.form.client_id),callback:function ($$v) {_vm.$set(_vm.form, "client_id", $$v)},expression:"form.client_id"}}),(_vm.errors.hasOwnProperty('client_id'))?_c('div',{staticClass:"text-left font-weight-bold text-danger mb-4 mt-2"},[_c('small',[_vm._v(_vm._s(_vm.errors.client_id[0]))])]):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-lg-6"},[_c('label',{attrs:{"for":"lawyer_id"}},[_vm._v("Abogado")]),_c('v-select',{attrs:{"options":_vm.lawyers_dropdown,"id":"lawyer_id","reduce":function (r) { return r.code; }},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
return [(searching)?[_vm._v(" No se encontraron resultados para "),_c('em',[_vm._v(_vm._s(search))]),_vm._v(". ")]:_c('em',{staticStyle:{"opacity":"0.5"}},[_vm._v(" Empiece a escribir para buscar. ")])]}}]),model:{value:(_vm.form.lawyer_id),callback:function ($$v) {_vm.$set(_vm.form, "lawyer_id", $$v)},expression:"form.lawyer_id"}}),(_vm.errors.hasOwnProperty('lawyer_id'))?_c('div',{staticClass:"text-left font-weight-bold text-danger mb-4 mt-2"},[_c('small',[_vm._v(_vm._s(_vm.errors.lawyer_id[0]))])]):_vm._e()],1),(_vm.form.lawyer_id && _vm.records_dropdown.length && !_vm.setRecord)?_c('div',{staticClass:"form-group col-lg-6"},[_c('label',{attrs:{"for":"record_id"}},[_vm._v("Expediente")]),_c('v-select',{attrs:{"options":_vm.records_dropdown,"id":"record_id","reduce":function (r) { return r.code; }},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
return [(searching)?[_vm._v(" No se encontraron resultados para "),_c('em',[_vm._v(_vm._s(search))]),_vm._v(". ")]:_c('em',{staticStyle:{"opacity":"0.5"}},[_vm._v(" Empiece a escribir para buscar. ")])]}}],null,false,194695259),model:{value:(_vm.form.record_id),callback:function ($$v) {_vm.$set(_vm.form, "record_id", $$v)},expression:"form.record_id"}}),(_vm.errors.hasOwnProperty('record_id'))?_c('div',{staticClass:"text-left font-weight-bold text-danger mb-4 mt-2"},[_c('small',[_vm._v(_vm._s(_vm.errors.record_id[0]))])]):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"notes"}},[_vm._v("Notas")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.notes),expression:"form.notes"}],staticClass:"form-control",attrs:{"name":"notes","id":"notes","cols":"30","rows":"5"},domProps:{"value":(_vm.form.notes)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "notes", $event.target.value)}}}),(_vm.errors.hasOwnProperty('notes'))?_c('div',{staticClass:"text-left font-weight-bold text-danger mb-4 mt-2"},[_c('small',[_vm._v(_vm._s(_vm.errors.notes[0]))])]):_vm._e()]),_c('div',{staticClass:"row justify-content-end"},[_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-gradient-info btn-md",on:{"click":function($event){$event.preventDefault();return _vm.createResource($event)}}},[_vm._v("Guardar")])])])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }